.randomizeButton {
  background-color: transparent;
  border: solid 3px #2196f3;
  border-radius: 10px;
  color: #2196f3;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 32px;
}

.randomizeButton:hover:enabled {
  background-color: #2196f3;
  color: #333;
}

.randomizeButton:active:enabled {
  background-color: #eee;
  color: #2196f3;
}

.randomizeButton:disabled {
  color: #333;
  border-color: #333;
}
