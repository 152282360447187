.page {
  border: solid 3px #2196f3;
  border-radius: 10px;
  margin: 0px 10px 10px 0px;
  padding: 0px 10px 10px 10px;
  flex-grow: 1;
  flex-shrink: 1;
}

.paramList {
  padding-left: 0px;
}

.paramList li {
  list-style-type: none;
}

.checkLabel {
  text-align: left;
  display: block;
  position: relative;
  padding-left: 2rem;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkLabel:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkLabel input:checked ~ .checkmark {
  background-color: #2196f3;
}

.checkLabel input:disabled ~ .checkmark {
  background-color: #999;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkLabel input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkLabel .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
