.linkButton {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #2196f3;
  text-decoration: underline;
  cursor: pointer;
}

.pagesContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
