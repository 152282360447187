body {
  font-family: sans-serif;
  background-color: #222;
  color: #eee;
}

.mainContainer {
  margin: auto;
  text-align: center;
}

.container {
  margin-bottom: 3rem;
}

h1 {
  padding-left: 10px;
  margin-bottom: 30px;
  color: #2196f3;
  font-weight: normal;
  font-size: 3rem;
}

a,
a:link,
a:visited {
  color: #2196f3;
}
